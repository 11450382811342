@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.range-slider-container {
  width: 100%;
  padding: 20px;
}

.range-slider {
  position: relative;
  width: 100%;
}

.range-slider input[type="range"] {
  width: 100%;
  appearance: none;
  background: transparent;
}

.range-slider input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  background: #37A2BA;
  border-radius: 50%;
  cursor: pointer;
}

.range-slider .tooltip {
  position: absolute;
  top: -30px;
  transform: translateX(-50%);
  background-color: #37A2BA;
  color: white;
  padding: 5px;
  border-radius: 3px;
}

.dragging .tooltip {
  display: block;
}
