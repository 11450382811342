#articles {
    .banner {
        background: linear-gradient(180deg, rgba(184, 224, 219, 0.50) 0%, #FFF 100%);

    }
}

#article {
    .banner {
        background: linear-gradient(180deg, #FFF,  #FFF 100%);

    }
}

.section {
    padding-top: 50px;
}

.card_detail {
    width: 24%;
}
// @media only screen and (max-width: 600px) {
//     #articles {
//         .banner {
//            padding: 180px 10px 90px 10px;
//         }
//     }

// }