@import url('./pricing.css');
@import url('./util.css');

@media (max-width: 768px) {
    .w-9\/10 {
        width: 90%;
    }
}

.contact-information-popover{
    display: none;
    border-radius: 8px;
}
.contact-information-popover::before{
    display: block;
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 14px solid white;
    right: 100%;
    bottom: 5%;
    box-shadow: 20px 20px #0000;
    z-index: 99999999;

}
.priceInfo-hover{
    display: none;
    border-radius: 8px;
}
.priceInfo-hover::before{
    display: block;
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 14px solid white;
    right: 100%;
    bottom: 5%;
    box-shadow: 20px 20px #0000;
    z-index: 99999999;
}
.trailingicon:hover ~ .contact-information-popover{
    display: block;
}
.priceInfo:hover ~ .priceInfo-hover{
    display: block;
}

.traiangle-left {
    height: 0px;
    width: 0px;
    position: relative;
    border-right: solid 14px #C4C4C4;
    border-bottom: solid 14px transparent;
    border-top: solid 14px transparent;
  }

  .traiangle-right {
    height: 0px;
    width: 0px;
    position: relative;
    border-top: solid 14px transparent;
    border-left: solid 14px #C4C4C4;
    border-bottom: solid 14px transparent;
  }

  .page-banner {
    display: flex;
    height: 98px;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    background: linear-gradient(90deg, #007583 0%, #122D5C 100%);
}

.page-banner h4 {
color: var(--Grey-scale-White, #FFF);
text-align: center;
font-family: "DM Sans";
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 26px; /* 108.333% */
letter-spacing: 0.4px;
}


.d-none {
    display: none !important;
}

.pointer {
    cursor: pointer;
}


.badge {
    display: inline-block;
    padding: 3px 7px;
    white-space: nowrap;
    vertical-align: middle;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.06);

}
.badge-primary {
    background: var(--Primary-Blue_brand-200, #D2F6FF);
}

.badge-light {
    background: var(--Secondary-Blue_1-100, #EBF3FF);
}

.text-muted {
    color: var(--tones-less-saturation-blue-3100, #595A5C) !important;
}

.text-primary {
    color: rgba(2, 2, 81, 1);
}

.text-light {
    color: #37A2BA !important
}

.display-5 {
    font-size: 40px;
}

.card {
    /* border-radius: 8px;
    border: 1px solid var(--tones-less-saturation-green-100, #D9E0DF);

    box-shadow: 0px 0.2px 4px 0px rgba(0, 0, 0, 0.12); */
}

.card-body {
    padding: 20px;
}


.bg-white {
    background: var(--Grey-scale-White, #FFF);
}

.avatar {
    display: flex !important;
    align-items: center;
    gap: 4px;

}

.avatar img {
    width: 35px;
    height: 35px;
}

.br-50 {
    border-radius: 50%
}
.fs-xs {
    font-size: 12px;
}

.fs-sm {
    font-size: 16px;
}

.fs-lg {
    font-size: 22px;
}

.fw-bold {
    font-weight: bold;
}

.fw-light {
    font-weight: lighter;
}

.fw-normal {
    font-weight: normal;
}

.br-theme {
    border-right: 1px solid #9FCDD7;
    padding-right: 20px;
}

.text-lighter {
    color: var(--Grey-scale-Medium-grey, #A0ABBE);
}

.my-auto {
    margin: auto 0px !important;
}

.mx-auto {
    margin: 9 auto !important;
}

.my-5 {
    margin: 3rem 0px;
}

.my-4 {
    margin: 2.5rem 0px;
}

.my-3 {
    margin: 2rem 0px;
}

.my-2 {
    margin: 1.5rem 0px;
}

.my-1 {
    margin: 1rem 0px;
}

.w-100 {
    width: 100% !important;
}

.position-absolute {
    position: absolute;
}

.w-50 {
    width: 50%;
}

.w-75 {
    width: 75%;
}

.mr-auto {
    margin: inherit auto;
}

.flex-column {
    flex-direction: column !important;
}



.h3 {
    font-size: 24px;
}

.h2 {
    font-size: 30px;
}

.section-lg {
    margin: 300px 0;
}


.section {
    padding: 180px 100px 90px 100px;
}

.pr-1 {
    padding: 0 .5rem;
}

.border-0 {
   border: none;
}

.bg-theme-light {
    background: var(--Secondary-Green-300, #CFFBF5);
}

.bg-theme-light-2 {
    background: var(--Secondary-Blue_1-100, #EBF3FF);

}

.rounded-pill {
    border-radius: 20px;
}

.position-sticky {
    position: sticky !important;
    top: 0;
}

.position-relative {
    position: relative !important;
}

.text-underline {
    text-decoration: underline;
}

.text-success {
    color: #003831 !important;
}

.br-1 {
    border-radius: 6px;
}

.flex-reverse {
    flex-direction: column-reverse !important;
}

.btn {
    font-size: 14px;
    padding: 6px 12px;
}

.btn-primary {
    border-radius: 5px;
    background: var(--Secondary-Purple-900, #020251);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    color: #fff !important;

}

.btn-default {
    border-radius: 6px;
background: var(--Grey-scale-White, #FFF);
box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root {
    padding-left: 0px !important;
}

.step-item .count {
    position: relative;
    display: flex;
    text-align: center;
    width: 40px;
    height: 40px;
    margin: 20px auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 100px;
    background: var(--tones-less-saturation-blue-brand-300, #9FCDD7);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
}

.step-item .step-arrow {
    position: absolute;
    top: auto;
    left: 60px;
}

.box {
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
}

.glass-card {
   /* From https://css.glass */
    background: rgba(255, 255, 255, 0.3);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(100px);
    -webkit-backdrop-filter: blur(100px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 500px;
    padding: 30px 70px;
    margin: auto;
}

.glass-card h1 {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
}

.glass-card h4 {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
}

#cta-whoweare {
    margin-bottom: 150px;
    font-size: 40px;
    font-weight: 700;
    color: #37A2BA;

}

.card-cover {
    height: 190px;
}

.modal {
    height: 100vh;
    width: 100vw;
    overflow: hidden !important;
    top: 0;
    position: fixed;
    background: rgba(234, 236, 240, 0.50);
    backdrop-filter: blur(12.5px);
    z-index: 1;
    border-radius: 6px;
}

.modal-dialog {
    padding: 20px 10px;
    margin: 30vh auto;
    width: 500px;
    z-index: 1;
    border-radius: 6px;
    background: var(--Grey-scale-White, #FFF);
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.25), -2px -2px 6px 0px rgba(0, 0, 0, 0.25), 1px 1px 3px 0px #FFF, -1px -1px 3px 0px #FFF;
}

.modal-content {
    padding: 30px;
}


@media only screen and (max-width: 600px) {
    .section {
        padding: 50px 20px 30px 20px;
    }
    .display-5 {
        font-size: 25px;
    }

    .navbar-links {
        display: none;
    }

    .step-item .step-arrow {
       display: none;
    }

    .box {
       margin-bottom: 20px;
    }

    .flex-column-sm {
        flex-direction: column;
    }

    .glass-card  {
        padding: 30px;
    }

    .glass-card h1 {
        font-size: 18px;
    }

    .glass-card h4 {
        font-size: 14px;

    }

    #cta-whoweare {
        font-size: 14px;
    }

    .justify-center-sm {
        justify-content: center !important;
    }

    .modal-dialog {
        width: 95%;
    }

}



