

.pricing .rows {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.pricing .card {
    flex: 1  30%;
    margin-bottom: 70px;
    padding: 20px 30px;
    border-radius: 8px;
    background: var(--Grey-scale-White, #FFF);
    box-shadow: -0.4px -0.4px 16px 0px rgba(211, 211, 255, 0.25), 0.4px 0.4px 16px 0px #D3D3FF;
}

.pricing .card:last-child {
    flex: none;
    width: 40%;
    padding: 20px 30px;
    border-radius: 8px;
    background: var(--Grey-scale-White, #FFF);
    box-shadow: -0.4px -0.4px 16px 0px rgba(211, 211, 255, 0.25), 0.4px 0.4px 16px 0px #D3D3FF;
}

@media only screen and (max-width: 600px) {
    .pricing .card {
        margin-bottom: 30px;
    }
    .pricing .card {
        width: 100%;
     }
    .pricing .card:last-child {
       width: 100%;
    }

    .pricing .items span {
        line-height: 15px !important;
        padding: 7px 0px;
    }
  }


.pricing .items {
    display: flex;
    align-items: center;
    gap: 4px;

}

.pricing .items span {
color: var(--Secondary-Blue_1-900, #001A3D);
font-family: "DM Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
letter-spacing: 0.4px;
line-height: 28px;
}

.pricing .items .icon {
    flex: 0 0 15px;
    }


.pricing .details {
    text-align: center;
    padding: 20px 0px 30px 0px;
}

.pricing .details h1 {
font-family: "DM Sans";
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 26px; /* 108.333% */
letter-spacing: 0.4px;
}

.pricing .details span {
color: var(--tones-less-saturation-blue-4200, #6E7785);
font-family: "DM Sans";
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 12px; /* 120% */
}

.pricing span.fw-bold {
    font-weight: 700;
    font-size: 18px;
}

.pricing .heading h1 {
    color: var(--secondary-purple-500-main, #5858DF);
    font-family: "DM Sans";
    font-size: 18px;
    font-style: normal;
    line-height: 26px; /* 108.333% */
    letter-spacing: 0.4px;
    text-align: center;
    border-bottom: 1px #f2efef solid;
    padding:0px 0px 13px 0px;
}

.pricing .card-footer {
    display: flex;
    justify-content: end;
    margin: 30px 0px 0px;
}

.pricing .card-footer button {
    color: var(--Grey-scale-White, #FFF);
    font-size: 13px;
    font-weight: 500;
    padding: 6px 12px;
    letter-spacing: 0.4px;
    border-radius: 6px;
    background: var(--Secondary-Purple-900, #020251);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
    display: flex !important;
width: 239px;
padding: 15px 14px;
gap: 10px !important;
color: #fff !important;
font-size: 12px;
    border-radius: 10px !important;
border: 1px solid var(--Primary-Blue_brand-100, #EBFBFF) !important;
background: var(--Primary-Blue_brand-600, #37A2BA) !important;
box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.25) !important;
}
